
import ApiUrl from '@/core/services/ApiUrl'
import { post, article, influencer } from './collections'
import subscriptions from './subscriptions'
import typeform from './typeform'
import { env } from '@/bootstrap/registerGlobalHelpers'

export default {
  app: {
    debug: env('DEBUG', false),
    mediaPackUrl: 'https://d1e946pvzmbumk.cloudfront.net/assets/img/Pouch-Press-Kit1.zip',
    accountWebsiteUrl: env('ACCOUNT_URL')
  },

  assets: {
    distributionUrl: env('ASSETS_DISTRIBUTION_URL', '/static/')
  },

  apiEndpoints: {
    // Contact service Endpoint
    contactUs: new ApiUrl('https://hy5898foq5.execute-api.eu-west-2.amazonaws.com/LATEST/contact-us'),
    // GSG Content Endpoints
    gsgAllDomains: new ApiUrl(`${env('CONTENT_API_ORIGIN')}/prod/deals/{locale}/index`, { cacheTimeout: 3600 }),
    gsgActiveDomains: new ApiUrl(`${env('CONTENT_API_ORIGIN')}/prod/deals/{locale}/activeDomains`, { cacheTimeout: 3600 }),
    gsgMerchantDetail: new ApiUrl(`${env('CONTENT_API_ORIGIN')}/prod/deals/{locale}/{id}`, { localCache: false }),
    gsgFeaturedVouchers: new ApiUrl(`${env('CONTENT_API_ORIGIN')}/prod/deals/{locale}/featuredVouchers`, { cacheTimeout: 1800 }),
    // Subscription Service Endpoint
    mailchimp: new ApiUrl(env('MAILCHIMP_SUBSCRIPTION')),
    // Legacy Unistall Tracking Endpoint
    uninstallTracking: new ApiUrl('https://api.joinpouch.io/api/v1/user/uninstall'),
    // Pouch API endpoints
    getPoints: new ApiUrl(env('POUCH_API_GET_POINTS')),
    getActivity: new ApiUrl(env('POUCH_API_GET_ACTIVITY')),
    getGiftCards: new ApiUrl(`${env('POUCH_API_GET_GIFTCARDS')}/{id}`),
    redeemPoints: new ApiUrl(`${env('POUCH_API_REDEEM_POINTS')}`),
    getUserData: new ApiUrl(`${env('POUCH_API_USER_DATA')}/{id}`, { cacheTimeout: 86400 }),
    postDeleteAccount: env('POUCH_API_DELETE_USER_ACCOUNT')
  },

  /**
   * Minimum redeemable Points, leaving here for
   * now as it's easier to hardcode this single value.
   */
  myaccount: {
    minRedeemablePoints: '500'
  },

  auth: {
    region: env('AUTH_REGION'),
    cognitoClientId: env('AUTH_COGNITO_CLIENT_ID'),
    cognitoDomain: env('AUTH_COGNITO_DOMAIN'),
    cognitoUserPoolId: env('AUTH_COGNITO_USER_POOL_ID'),
    cognitoSignInRedirectUrl: env('AUTH_COGNITO_SIGNIN_REDIRECT_URL'),
    cognitoSignOutRedirectUrl: env('AUTH_COGNITO_SIGNOUT_REDIRECT_URL'),
    cognitoCookieStorageDomain: env('AUTH_COGNITO_COOKIE_STORAGE_DOMAIN'),
    cognitoCookieStorageSecure: env('AUTH_COGNITO_COOKIE_STORAGE_SECURE')
  },

  cid: {
    gb: env('CLIENT_ID_GB'),
    de: env('CLIENT_ID_DE'),
    br: env('CLIENT_ID_BR'),
    nl: env('CLIENT_ID_NL'),
    fr: env('CLIENT_ID_FR'),
    es: env('CLIENT_ID_ES')
  },

  clickout: {
    baseUrl: {
      gb: env('MEGATRON_CLICKOUT_URL_GB'),
      de: env('MEGATRON_CLICKOUT_URL_DE'),
      br: env('MEGATRON_CLICKOUT_URL_BR'),
      nl: env('MEGATRON_CLICKOUT_URL_NL'),
      fr: env('MEGATRON_CLICKOUT_URL_FR'),
      es: env('MEGATRON_CLICKOUT_URL_DE')
    }
  },

  clickoutLink: {
    baseUrl: {
      gb: env('MERCURY_CLICKOUT_URL_GB')
    }
  },

  scraping: {
    endpoint: new ApiUrl('https://api.allorigins.win/raw?url={url}', { localCache: false })
  },

  googleForms: {
    onboarding: {
      action: env('ONBOARDING_FORM_ACTION'),
      questionIds: {
        helpful: env('ONBOARDING_FORM_HELPFUL_ID'),
        because: env('ONBOARDING_FORM_BECAUSE_ID')
      }
    }
  },

  social: {
    pages: {
      facebook: 'https://www.facebook.com/JoinPouch',
      twitter: 'https://twitter.com/joinpouch',
      instagram: 'https://www.instagram.com/joinpouch/'
    },

    sharing: {
      facebook: '',
      twitter: '',
      instagram: ''
    }
  },

  localization: {
    availableCountries: ['gb', 'de'],
    availableTranslations: ['en', 'de'],
    defaultCountry: 'gb',
    defaultTranslation: 'en'
  },

  messengerBot: {
    url: 'https://m.me/JoinPouch?ref='
  },

  facebookCommunity: {
    url: 'https://www.facebook.com/groups/1508158729231181/'
  },

  extension: {
    demoUrl: 'https://google.com',
    installationLinks: {
      chrome: 'https://chrome.google.com/webstore/detail/pouch-automatic-coupons-a/eoolfmmapnkhandljfaaofncecfakljd',
      edge: 'https://microsoftedge.microsoft.com/addons/detail/fgfaoebkidonmphkllbnbnjobjdkhhpm',
      firefox: 'https://addons.mozilla.org/en-US/firefox/addon/pouch/',
      safari: 'https://safari-extensions.apple.com/details/?id=com.onerippleltd.pouch-85BEM6STYL'
    }
  },

  style: {
    primaryColor: '#ff6441'
  },

  analytics: {
    active: env('ANALYTICS', true),
    tokens: {
      ga: env('GA_TOKEN'),
      gtm: env('GTM_TOKEN'),
      mixpanel: env('MIXPANEL_TOKEN'),
      fbq: {
        gb: env('FBQ_TOKEN_GB'),
        de: env('FBQ_TOKEN_DE')
      },
      extGa: env('EXTENSIONS_GA_TOKEN')
    },
    mgtrx: {
      cid: env('CLIENT_ID_GB'),
      collect: {
        gb: env('MGTRX_COLLECT_GB'),
        de: env('MGTRX_COLLECT_DE')
      },
      library: env('MGTRX_LIB'),
      name: env('MGTRX_NAME'),
      pointer: env('MGTRX_POINTER')
    }
  },

  cms: {
    backend: {
      name: 'bitbucket',
      repo: env('CMS_BITBUCKET_REPOSITORY'),
      branch: 'master',
      auth_type: 'implicit',
      app_id: env('CMS_BITBUCKET_APP_KEY')
    },
    collections: [post, article, influencer],
    load_config_file: false,
    media_folder: 'public/static/images/blog-assets',
    public_folder: '/static/images/blog-assets',
    publish_mode: 'editorial_workflow',
    site_url: 'https://joinpouch.com'
  },

  dealsPage: 'https://www.deals.joinpouch.com/',
  blackFridayDealsUrl: 'https://www.deals.joinpouch.com/black-friday-cyber-week',
  ouchDealsUrl: 'https://www.deals.joinpouch.com/ouch?utm_medium=offline&utm_source=TV&utm_campaign=PouchSkyH1_2023',
  zenDeskHelp: 'https://pouch.zendesk.com/hc/en-gb',

  accountLoginUrl: env('ACCOUNT_LOGIN_URL'),
  accountJoinUrl: env('ACCOUNT_JOIN_URL'),
  accountLogoutUrl: env('ACCOUNT_LOGOUT_URL'),
  accountDiscoverBrandsUrl: env('ACCOUNT_DISCOVER_BRANDS_URL'),

  studentDeals: {
    studentBeansUrl: 'https://pouch.publish.studentbeans.com/uk'
  },

  subscriptions,

  typeform
}
